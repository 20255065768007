import React, { useEffect, useRef, useState } from "react";

import Footer from "./common/Footer";
import Header from "./common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import {
  dispatchKycConfig,
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetMotorQuote,
  dispatchUserDetails,
  resetQuickQuoteResults,
  resetSelectedPlan,
  selectedPlanAction,
} from "../store/action/userActions";
import {
  addDocuments,
  addDocumentsForMotorCustomer,
  createCustomer,
  digitCreateQuoteApiCall,
  digitPaymentApi,
  getPreviousJourneyDetails,
  processFlow,
  savePolicyToDatabase,
  sompoPaymentApi,
} from "./services/TPApiCall";
import KotakPayment from "./Additional/KotakPayment";
import { FaCheckCircle } from "react-icons/fa";
import {
  IoIosArrowDown,
  IoIosArrowDropdown,
  IoIosArrowForward,
} from "react-icons/io";
import Layout from "./common/Layout";
import moment from "moment";
import { PostImageDataWithToken } from "../api/apiHelper";
// import { useToasts } from "react-toast-notifications";
import {
  POLICY_TYPE,
  caseStatus,
  decryptAes256Text,
  documentType,
  extractCustomerLeadId,
  moveToTop,
} from "./utility/constants";
import { useCookies } from "react-cookie";
import { Modal, ModalBody } from "reactstrap";
import SharePaymentQuotationModal from "./common/SharePaymentQuotationModal";
import { toast } from "react-toastify";
const PolicyOverview = () => {
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const QuickQouteResult = useSelector((state) => state.root.QuickQouteResult);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const [displayOverview, setDisplayOverview] = useState(false);
  const [responseDocData, setResponseDocData] = useState(null);
  const [paymentSpinner, setPaymentSpinner] = useState(false);
  const [extractUrlCustomerLeadId, setExtractUrlCustomerLeadId] =
    useState(false);
  const [shareMobileNumber, setShareMobileNumber] = useState(
    motorRequest?.mobileNumber
  );
  const [images, setImages] = useState({
    rcFront: null,
    rcBack: null,
    insurance1: null,
    insurance2: null,
    salesLetter: null,
  });
  const [imagesLink, setImagesLink] = useState({});
  const [shareLinkText, setShareLinkText] = useState("");
  const [sharePaymentModal, setSharePaymentModal] = useState(false);
  const toggleSharePaymentModal = () =>
    setSharePaymentModal(!sharePaymentModal);
  const [cookies, setCookie, removeCookie] = useCookies(["customerLeadId"]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm();

  const handleUploadFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (
        e.target.files[0].type.includes("image") === true ||
        e.target.files[0].type.includes("pdf") === true
      ) {
        setImages((prevImages) => ({
          ...prevImages,
          [e.target.name]: e.target.files[0],
        }));
      } else {
        alert("Error in uploading images");
      }
    } else {
      console.log("No file selected");
    }
  };

  const documentsUpload = (data) => {};

  const [disabled, setDisabled] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const journeyDetails = {
      previousProcessJourney: location?.state?.previousProcessJourney,
      stepsData: location?.state?.stepsData,
    };

    if (journeyDetails?.previousProcessJourney && journeyDetails?.stepsData) {
      updateJourneyData(
        journeyDetails?.stepsData,
        journeyDetails?.previousProcessJourney?.processStep,
        journeyDetails?.previousProcessJourney?.processDescription
      );
    } else {
      const urlCustomerLeadId = extractCustomerLeadId(location?.pathname);
      if (urlCustomerLeadId !== motorRequest?.customerLeadId) {
        dispatchResetMotorQuote();
        dispatch(resetQuickQuoteResults());
        dispatch(resetSelectedPlan());
      }
      setExtractUrlCustomerLeadId(true);
      dispatchMotorQuote("customerLeadId", urlCustomerLeadId);
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", urlCustomerLeadId, { expires });

      getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId })
        .then((response) => {
          const stepsData = response?.data?.stepsData;
          // debugger;
          if (
            (response?.data?.processStep === "step8" &&
              response?.data?.processDiscription === "kycAndUserDetails") ||
            (response?.data?.processStep === "step9" &&
              response?.data?.processDiscription === "nomineeDetails") ||
            (response?.data?.processStep === "step10" &&
              response?.data?.processDiscription === "vehicleDetails") ||
            (response?.data?.processStep === "step11" &&
              response?.data?.processDiscription === "checkoutPage")
          ) {
            updateJourneyData(
              stepsData,
              response?.data?.processStep,
              response?.data?.processDiscription
            );
          } else if (
            response?.data?.processStep === "step13" &&
            response?.data?.processDiscription === "pdfGeneration"
          ) {
            const caseId = stepsData?.step13?.motorRequest?.caseId;
            for (let key in stepsData?.step13?.motorRequest) {
              dispatchMotorQuote(
                `${key}`,
                stepsData?.step13?.motorRequest[key]
              );
            }
            navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching previous journey details:", error);
        });
    }
    moveToTop();
    // if (
    //   motorRequest?.businessType === "new" ||
    //   motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY ||
    //   motorRequest?.isOwnerChanged
    // ) {
    //   motorQuotation(motorRequest);
    // }
  }, []);

  const updateJourneyData = (stepsData, processStep, processDiscription) => {
    try {
      dispatchKycConfig(stepsData?.step8?.kycConfig);
      dispatchProcessFlowMotor("step8", stepsData?.step8);
      // dispatchMotorQuote(
      //   "registrationNumber",
      //   stepsData?.step1?.registrationNumber.replace(/-/g, "")
      // );
      // dispatchMotorQuote(
      //   "rtoCode",
      //   stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      // );
      // dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);

      // dispatchMotorQuote("makeId", stepsData?.step2?.make?.value || "");
      // dispatchMotorQuote("makeName", stepsData?.step2?.make?.label || "");
      // dispatchMotorQuote("modelId", stepsData?.step3?.model?.value || "");
      // dispatchMotorQuote("modelName", stepsData?.step3?.model?.label || "");
      // dispatchMotorQuote(
      //   "variantId",
      //   stepsData?.step4?.variantSelection?.value || ""
      // );
      // dispatchMotorQuote(
      //   "variantName",
      //   stepsData?.step4?.variantSelection?.label || ""
      // );
      // dispatchMotorQuote(
      //   "fuelType",
      //   stepsData?.step4?.variantSelection?.fuelType
      // );

      // for (let key in stepsData?.step5?.customerDetails) {
      //   dispatchUserDetails(`${key}`, stepsData?.step5?.customerDetails[key]);
      // }

      // let newData = {
      //   firstName: "",
      //   middleName: "",
      //   lastName: "",
      // };
      // const nameParts =
      //   stepsData?.step5?.customerDetails.customer_name.split(" ");

      // if (nameParts.length > 0) {
      //   newData.firstName = nameParts[0];
      // }

      // if (nameParts.length > 2) {
      //   newData.middleName = nameParts[1];
      // }

      // if (nameParts.length > 1) {
      //   newData.lastName = nameParts[nameParts.length - 1];
      // }

      // for (let key in newData) {
      //   dispatchMotorQuote(`${key}`, newData[key]);
      // }
      // dispatchMotorQuote(
      //   "email",
      //   stepsData?.step5?.customerDetails?.customer_email
      // );
      // dispatchMotorQuote(
      //   "mobileNumber",
      //   stepsData?.step5?.customerDetails?.customer_phone
      // );

      // dispatchMotorQuote("customerType", stepsData?.step5?.customerType || "");
      // dispatchMotorQuote(
      //   "isOwnerChanged",
      //   stepsData?.step5?.isOwnerChanged || false
      // );
      // dispatchMotorQuote(
      //   "manufaturingDate",
      //   stepsData?.step5?.manufaturingDate || ""
      // );

      // for (let key in stepsData?.step6?.pypSelection) {
      //   dispatchMotorQuote(`${key}`, stepsData?.step6?.pypSelection[key]);
      // }

      // for (let key in stepsData?.step7?.previousClaimData) {
      //   dispatchMotorQuote(`${key}`, stepsData?.step7?.previousClaimData[key]);
      // }
      for (let key in stepsData?.step8?.kycAndUserDetails) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.kycAndUserDetails[key]);
      }
      for (let key in stepsData?.step10?.motorRequest) {
        dispatchMotorQuote(`${key}`, stepsData?.step10?.motorRequest[key]);
      }
      for (let key in stepsData?.step10?.vehicleDetails) {
        dispatchMotorQuote(`${key}`, stepsData?.step10?.vehicleDetails[key]);
      }
      dispatch(selectedPlanAction(stepsData?.step8?.planDetails));
      // dispatchKycConfig(stepsData?.step8?.kycConfig);

      // if (stepsData?.step8?.kycAndUserDetails?.addressLine1) {
      //   if (stepsData.step8.planDetails.PersonalAccident) {
      //     if (
      //       processStep === "step9" &&
      //       processDiscription === "nomineeDetails"
      //     ) {
      //       for (let key in stepsData?.step9?.motorRequest) {
      //         if (stepsData?.step9?.motorRequest.hasOwnProperty(key)) {
      //           dispatchMotorQuote(
      //             `${key}`,
      //             stepsData?.step9?.motorRequest[key]
      //           );
      //         }
      //       }
      //       for (let key in stepsData?.step9?.nomineeDetails) {
      //         if (stepsData?.step9?.nomineeDetails.hasOwnProperty(key)) {
      //           dispatchMotorQuote(
      //             `${key}`,
      //             stepsData?.step9?.nomineeDetails[key]
      //           );
      //         }
      //       }
      //       setCurrentStep(3);
      //     } else if (
      //       processStep === "step10" &&
      //       processDiscription === "vehicleDetails"
      //     ) {
      //       for (let key in stepsData?.step10?.motorRequest) {
      //         if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
      //           dispatchMotorQuote(
      //             `${key}`,
      //             stepsData?.step10?.motorRequest[key]
      //           );
      //         }
      //       }
      //       for (let key in stepsData?.step10?.vehicleDetails) {
      //         if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
      //           dispatchMotorQuote(
      //             `${key}`,
      //             stepsData?.step10?.vehicleDetails[key]
      //           );
      //         }
      //       }
      //       setCurrentStep(3);
      //     } else {
      //       setCurrentStep(2);
      //     }
      //   } else if (
      //     processStep === "step10" &&
      //     processDiscription === "vehicleDetails"
      //   ) {
      //     for (let key in stepsData?.step10?.motorRequest) {
      //       if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
      //         dispatchMotorQuote(
      //           `${key}`,
      //           stepsData?.step10?.motorRequest[key]
      //         );
      //       }
      //     }
      //     for (let key in stepsData?.step10?.vehicleDetails) {
      //       if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
      //         dispatchMotorQuote(
      //           `${key}`,
      //           stepsData?.step10?.vehicleDetails[key]
      //         );
      //       }
      //     }
      //     setCurrentStep(3);
      //   } else {
      //     setCurrentStep(3);
      //   }
      // } else
      if (processStep === "step11" && processDiscription === "checkoutPage") {
        const caseId = stepsData?.step11?.motorRequest?.caseId;
        for (let key in stepsData?.step11?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step11?.motorRequest[key]);
        }
        for (let key in stepsData?.step11?.documents) {
          setImagesLink((prev) => ({
            ...prev,
            [key]: stepsData?.step11?.documents[key],
          }));
        }
      } else if (
        processStep === "step10" &&
        processDiscription === "vehicleDetails"
      ) {
        if (
          stepsData?.step10?.vehicleDetails?.proposalStatus !==
          caseStatus.proposalSuccess
        ) {
          navigate(
            `/proposal/${stepsData?.step10?.motorRequest?.customerLeadId}`
          );
        }
      } else if (
        processStep === "step13" &&
        processDiscription === "pdfGeneration"
      ) {
        const caseId = stepsData?.step13?.motorRequest?.caseId;
        for (let key in stepsData?.step13?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key]);
        }
        navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`);
      }
    } catch (error) {
      console.error("Error fetching previous journey details:", error);
    }
  };

  const createQuote = (e) => {
    e.preventDefault();
    setDisabled("disabled");

    setPaymentSpinner(true);
    window.location.href = motorRequest?.paymentUrl;
    setPaymentSpinner(false);
    const dataForFlow = {
      ...processFlowObjMotor,
      customerLeadId: motorRequest?.customerLeadId,
      processDiscription: "paymentGateway",
      step: "step12",
      step12: {
        motorRequest: { ...motorRequest },
        paymentStatus: caseStatus.paymentRequested,
      },
    };
    processFlow(dataForFlow);

    // setDisabled("");
  };

  const savePolicy = (apiId, inspection_no) => {
    let data = { ...apiRequestQQ, ...selectedPlan };
    if (apiId) {
      data.ApiId = apiId;
    }
    if (inspection_no) {
      data.inspection_no = inspection_no;
    }
    savePolicyToDatabase(data);
  };

  const [rightTab, setRightTab] = useState(1);
  const [tab, setTab] = useState(1);
  const handelTab = (key) => {
    if (tab == key) {
      setTab("");
    } else {
      setTab(key);
    }
  };
  const handelIRightTab = (key) => {
    if (rightTab == key) {
      setRightTab("");
    } else {
      setRightTab(key);
    }
  };

  useEffect(() => {
    if (Object.keys(imagesLink).length > 0) {
      sendFileLink();
      sendFileData();
    }
    let decryptedImages = [];
    for (let key in imagesLink) {
      decryptedImages[key] = decryptAes256Text(
        imagesLink[key],
        process.env.REACT_APP_DECRYPTION_KEY
      );
    }

    setImages({
      rcFront: decryptedImages.rcFront,
      rcBack: decryptedImages.rcBack,
      insurance1: decryptedImages.insurance1,
      insurance2: decryptedImages.insurance2,
      salesLetter: decryptedImages.salesLetter,
      invoice: decryptedImages.invoice,
    });

    const isPopulated = Object.keys(decryptedImages).some(
      (key) => decryptedImages[key]
    );

    if (isPopulated) {
      setDisplayOverview(true);
    } else {
      setDisplayOverview(false);
    }
  }, [imagesLink]);

  const handleDocuments = (data) => {
    for (let key in images) {
      const image = images[key];
      if (image) {
        const postData = {
          caseId: selectedPlan?.caseId,
          documentType: documentType?.motorDocuments,
          file: images[key],
        };
        addDocuments(postData)
          .then((response) => {
            if (response?.status === "success") {
              setImagesLink((prev) => ({ ...prev, [key]: response?.data }));
            } else {
              toast.error(response?.data?.errors[0]?.displayMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((error) => {
            console.error("Error adding document:", error);
          });
      }
    }

    // const postData = {
    //   customerUniqueNumber:
    //     processFlowObjMotor?.step5?.customerDetails?.customer_unique_number,
    //   caseId: selectedPlan?.caseId,
    //   customerLeadId: motorRequest?.customerLeadId,
    //   rc_front: imagesLink?.rcFront,
    //   rc_back: imagesLink?.rcBack,
    //   insurance1: imagesLink?.insurance1,
    //   insuranceLetter: imagesLink?.insurance2,
    //   salesLetter: imagesLink?.salesLetter,
    // };

    // console.log("postData", postData);
    // debugger;

    // addDocumentsForMotorCustomer(postData).then((response) => {
    //   console.log("response doc ", response);
    //   if (response?.success === true) {
    //     setResponseDocData(response?.data);
    //     addToast(response?.message, {
    //       appearance: "success",
    //       autoDismiss: 2000,
    //     });
    //   } else {
    //     addToast(response?.data?.message, {
    //       appearance: "error",
    //       autoDismiss: 2000,
    //     });
    //   }
    // });

    setDisplayOverview(true);
  };

  const sendFileData = () => {
    const docData = {
      rcFront: imagesLink?.rcFront,
      rcBack: imagesLink?.rcBack,
      insurance1: imagesLink?.insurance1,
      insurance2: imagesLink?.insurance2,
      salesLetter: imagesLink?.salesLetter,
      invoice: imagesLink?.invoice,
    };

    dispatchProcessFlowMotor("step11", docData);

    const newData = {
      ...processFlowObjMotor,
      customerLeadId: motorRequest?.customerLeadId,
      motorRequest: { ...motorRequest },
      step: "step11",
      processDiscription: "checkoutPage",
      step11: {
        // ...processFlowObjMotor.step11,
        documents: docData,
        motorRequest: { ...motorRequest },
      },
    };

    processFlow(newData);
  };

  const sendFileLink = () => {
    const postData = {
      customerUniqueNumber: motorRequest?.customerUniqueNumber,
      caseId: selectedPlan?.caseId,
      customerLeadId: motorRequest?.customerLeadId,
      rc_front: imagesLink?.rcFront,
      rc_back: imagesLink?.rcBack,
      insurance1: imagesLink?.insurance1,
      insuranceLetter: imagesLink?.insurance2,
      salesLetter: imagesLink?.salesLetter,
      invoice: imagesLink?.invoice,
    };

    // debugger;

    addDocumentsForMotorCustomer(postData).then((response) => {
      if (response?.success === true) {
        setResponseDocData(response?.data);
        // addToast(response?.message, {
        //   appearance: "success",
        //   autoDismiss: 2000,
        // });
      } else {
        // addToast(response?.data?.message, {
        //   appearance: "error",
        //   autoDismiss: 2000,
        // });
      }
    });
  };

  const handleChangeShareMobileNumber = (val) => {
    setShareMobileNumber(val);
  };

  const smsText = () => {
    const text = `Dear ${motorRequest?.firstName}, following is the link to make payment of Rs. ${selectedPlan?.FinalPremium} to get your vehicle insured with ${selectedPlan?.insurer}`;
    setShareLinkText(text);
  };

  return (
    <Layout>
      <div className="main-owner-page">
        <div className="po_main_container_outer">
          <div className="po_main_container row">
            {/* <form onSubmit={handleSubmit(documentsUpload)}> */}
            <div className="po_left_container">
              <form className="w-100" onSubmit={handleSubmit(handleDocuments)}>
                <h4 className="text-center">
                  {motorRequest?.isVehicleNew
                    ? "Please Upload the Invoice"
                    : "Please Upload the Mandatory Documents"}
                </h4>
                <div className="po_left_container_inner">
                  {motorRequest?.isVehicleNew ? (
                    <>
                      <div
                        className={`po_left_container_inner_div ${
                          images.invoice ? "completed" : ""
                        }`}
                        onClick={() => handelTab(1)}
                      >
                        <span>Upload Invoice</span>

                        {images.invoice ? (
                          <FaCheckCircle size={23} />
                        ) : tab == 1 ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowForward />
                        )}
                      </div>
                      {tab == 1 && (
                        <div className="po_left_container_inner_div_selected">
                          <div className="po_left_container_inner_div_selected_left">
                            <input
                              type="file"
                              className="d-none"
                              style={{ cursor: "pointer" }}
                              id={"invoice"}
                              name={"invoice"}
                              accept={"image/*,application/pdf"}
                              {...register("invoice", {
                                required: "Invoice is required",
                                onChange: (e) => handleUploadFile(e),
                              })}
                            />
                            <label
                              htmlFor={"invoice"}
                              className="d-flex flex-column justify-content-center align-items-center"
                            >
                              {images.invoice ? (
                                typeof images.invoice === "string" ||
                                images.invoice.type.includes("image") ? (
                                  <img
                                    src={
                                      typeof images.invoice === "string"
                                        ? images.invoice
                                        : URL.createObjectURL(images.invoice)
                                    }
                                    className=" d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={"/assets/images/pdf-svgrepo-com.svg"}
                                    className="d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                )
                              ) : (
                                <img
                                  src={"/assets/images/cameraIcon.svg"}
                                  className=" d-block"
                                  style={{
                                    objectFit: "contain",
                                    width: "3.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </label>
                            <span>Invoice</span>
                            {errors?.invoice && (
                              <p className="text-danger fs-6">
                                {errors?.invoice?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className={`po_left_container_inner_div ${
                          images.rcFront && images.rcBack ? "completed" : ""
                        }`}
                        onClick={() => handelTab(1)}
                      >
                        <span>
                          Upload RC Photo
                          <span className="text-danger">*</span>
                        </span>
                        {images.rcFront && images.rcBack ? (
                          <FaCheckCircle size={23} />
                        ) : tab == 1 ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowForward />
                        )}
                        {/* {tab == 1 ? <IoIosArrowDown /> : <IoIosArrowForward />} */}
                      </div>
                      {tab == 1 && (
                        <div className="po_left_container_inner_div_selected">
                          <div className="po_left_container_inner_div_selected_left">
                            <input
                              type="file"
                              className="d-none"
                              style={{ cursor: "pointer" }}
                              id={"rcFront"}
                              name={"back"}
                              accept={"image/*,application/pdf"}
                              {...register("rcFront", {
                                required: "Front RC is required",
                                onChange: (e) => handleUploadFile(e),
                              })}
                            />
                            <label
                              htmlFor={"rcFront"}
                              className="d-flex flex-column justify-content-center align-items-center"
                            >
                              {images.rcFront ? (
                                typeof images.rcFront === "string" ||
                                images.rcFront.type.includes("image") ? (
                                  <img
                                    src={
                                      typeof images.rcFront === "string"
                                        ? images.rcFront
                                        : URL.createObjectURL(images.rcFront)
                                    }
                                    className=" d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={"/assets/images/pdf-svgrepo-com.svg"}
                                    className="d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                )
                              ) : (
                                <img
                                  src={"/assets/images/cameraIcon.svg"}
                                  className=" d-block"
                                  style={{
                                    objectFit: "contain",
                                    width: "3.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </label>
                            <span>Front</span>
                            {errors?.rcFront && (
                              <p className="text-danger fs-6">
                                {errors?.rcFront?.message}
                              </p>
                            )}
                          </div>
                          <div className="po_left_container_inner_div_selected_left">
                            <input
                              type="file"
                              className="d-none"
                              style={{ cursor: "pointer" }}
                              id={"rcBack"}
                              name={"back"}
                              accept={"image/*,application/pdf"}
                              {...register("rcBack", {
                                required: "Back RC is required",
                                onChange: (e) => handleUploadFile(e),
                              })}
                            />
                            <label
                              htmlFor={"rcBack"}
                              className="d-flex flex-column justify-content-center align-items-center"
                            >
                              {images.rcBack ? (
                                typeof images.rcBack === "string" ||
                                images.rcBack.type.includes("image") ? (
                                  <img
                                    src={
                                      typeof images.rcBack === "string"
                                        ? images.rcBack
                                        : URL.createObjectURL(images.rcBack)
                                    }
                                    className=" d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={"/assets/images/pdf-svgrepo-com.svg"}
                                    className="d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                )
                              ) : (
                                <img
                                  src={"/assets/images/cameraIcon.svg"}
                                  className=" d-block"
                                  style={{
                                    objectFit: "contain",
                                    width: "3.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </label>
                            <span>Back</span>
                            {errors?.rcBack && (
                              <p className="text-danger fs-6">
                                {errors?.rcBack?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        className={`po_left_container_inner_div ${
                          images.insurance1 ? "completed" : ""
                        }`}
                        onClick={() => handelTab(2)}
                      >
                        <span>Upload Previous Policy</span>

                        {images.insurance1 ? (
                          <FaCheckCircle size={23} />
                        ) : tab == 2 ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowForward />
                        )}
                      </div>
                      {tab == 2 && (
                        <div className="po_left_container_inner_div_selected">
                          <div className="po_left_container_inner_div_selected_left">
                            <input
                              type="file"
                              className="d-none"
                              style={{ cursor: "pointer" }}
                              id={"insurance1"}
                              name={"insurance 1"}
                              accept={"image/*,application/pdf"}
                              {...register("insurance1", {
                                // required: "Insurance 1 is required",
                                onChange: (e) => handleUploadFile(e),
                              })}
                            />
                            <label
                              htmlFor={"insurance1"}
                              className="d-flex flex-column justify-content-center align-items-center"
                            >
                              {images.insurance1 ? (
                                typeof images.insurance1 === "string" ||
                                images.insurance1.type.includes("image") ? (
                                  <img
                                    src={
                                      typeof images.insurance1 === "string"
                                        ? images.insurance1
                                        : URL.createObjectURL(images.insurance1)
                                    }
                                    className=" d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={"/assets/images/pdf-svgrepo-com.svg"}
                                    className="d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                )
                              ) : (
                                <img
                                  src={"/assets/images/cameraIcon.svg"}
                                  className=" d-block"
                                  style={{
                                    objectFit: "contain",
                                    width: "3.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </label>
                            <span>Previous Policy</span>
                          </div>
                        </div>
                      )}
                      {motorRequest?.policyType === POLICY_TYPE.ODONLY && (
                        <>
                          <div
                            className={`po_left_container_inner_div ${
                              images.insurance2 ? "completed" : ""
                            }`}
                            onClick={() => handelTab(3)}
                          >
                            <span>Upload Previous TP Policy</span>
                            {images.insurance2 ? (
                              <FaCheckCircle size={23} />
                            ) : tab == 1 ? (
                              <IoIosArrowDown />
                            ) : (
                              <IoIosArrowForward />
                            )}
                          </div>
                          {tab == 3 && (
                            <div className="po_left_container_inner_div_selected">
                              <div className="po_left_container_inner_div_selected_left">
                                <input
                                  type="file"
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  id={"insurance2"}
                                  name={"insurance 2"}
                                  accept={"image/*,application/pdf"}
                                  {...register("insurance2", {
                                    required: "Insurance 2 is required",
                                    onChange: (e) => handleUploadFile(e),
                                  })}
                                />
                                <label
                                  htmlFor={"insurance2"}
                                  className="d-flex flex-column justify-content-center align-items-center"
                                >
                                  {images.insurance2 ? (
                                    typeof images.insurance2 === "string" ||
                                    images.insurance2.type.includes("image") ? (
                                      <img
                                        src={
                                          typeof images.insurance2 === "string"
                                            ? images.insurance2
                                            : URL.createObjectURL(
                                                images.insurance2
                                              )
                                        }
                                        className=" d-block"
                                        style={{
                                          objectFit: "contain",
                                          width: "3.5rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          "/assets/images/pdf-svgrepo-com.svg"
                                        }
                                        className="d-block"
                                        style={{
                                          objectFit: "contain",
                                          width: "3.5rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )
                                  ) : (
                                    <img
                                      src={"/assets/images/cameraIcon.svg"}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </label>
                                <span>Previous TP Policy</span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div
                        className={`po_left_container_inner_div ${
                          images.salesLetter ? "completed" : ""
                        }`}
                        onClick={() => handelTab(4)}
                      >
                        <span>Upload Sales Letter</span>
                        {images.salesLetter ? (
                          <FaCheckCircle size={23} />
                        ) : tab == 1 ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowForward />
                        )}
                      </div>
                      {tab == 4 && (
                        <div className="po_left_container_inner_div_selected">
                          <div className="po_left_container_inner_div_selected_left">
                            <input
                              type="file"
                              className="d-none"
                              style={{ cursor: "pointer" }}
                              id={"salesLetter"}
                              name={"salesLetter"}
                              accept={"image/*,application/pdf"}
                              {...register("salesLetter", {
                                // required: "Sales Letter is required",
                                onChange: (e) => handleUploadFile(e),
                              })}
                            />
                            <label
                              htmlFor={"salesLetter"}
                              className="d-flex flex-column justify-content-center align-items-center"
                            >
                              {images.salesLetter ? (
                                typeof images.salesLetter === "string" ||
                                images.salesLetter.type.includes("image") ? (
                                  <img
                                    src={
                                      typeof images.salesLetter === "string"
                                        ? images.salesLetter
                                        : URL.createObjectURL(
                                            images.salesLetter
                                          )
                                    }
                                    className=" d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={"/assets/images/pdf-svgrepo-com.svg"}
                                    className="d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                )
                              ) : (
                                <img
                                  src={"/assets/images/cameraIcon.svg"}
                                  className=" d-block"
                                  style={{
                                    objectFit: "contain",
                                    width: "3.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </label>
                            <span>Sales Letter</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="d-flex justify-content-center gap-5 w-100">
                    <button
                      type="button"
                      className="btn w-auto mt-2 upload-btn"
                      onClick={() =>
                        navigate(`/proposal/${motorRequest?.customerLeadId}`, {
                          state: { activeStep: 3 },
                        })
                      }
                      style={{ backgroundColor: "#56b96c", color: "#ffffff" }}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="btn w-auto mt-2 upload-btn"
                      style={{ backgroundColor: "#56b96c", color: "#ffffff" }}
                    >
                      Upload Documents
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* ------------Policy Details------------ */}
            {displayOverview && (
              <div className="po_right_container">
                <div
                  onClick={() => handelIRightTab(1)}
                  style={{ cursor: "pointer" }}
                  className={`po_right_container_tab ${
                    rightTab == 1 ? "po_right_container_tab_active" : ""
                  }`}
                >
                  <span>Policy Details</span>{" "}
                  {rightTab == 1 ? <FaMinus /> : <FaPlus />}
                </div>
                {rightTab == 1 && (
                  <div className="po_right_container_tab_active_tab">
                    <p>
                      Insurer{" "}
                      <span>
                        <img src={selectedPlan?.Logo} className="img-policy" />
                      </span>
                    </p>
                    <p>
                      Policy Type <span>{selectedPlan?.policyType}</span>
                    </p>
                    <p>
                      Policy Start Date{" "}
                      <span className="number">{selectedPlan?.StartDate}</span>
                    </p>
                    <p>
                      Policy End Date <span>{selectedPlan?.EndDate}</span>
                    </p>
                    {/* <p>
                    Tenure <span></span>
                  </p> */}
                    <p>
                      IDV <span>{selectedPlan?.idv}</span>
                    </p>
                    <p>
                      Net Premium <span>{selectedPlan?.NetPremium}</span>
                    </p>
                    <p>
                      GST 18% <span>{selectedPlan?.GST}</span>
                    </p>
                    <p>
                      Final Premium <span>{selectedPlan?.FinalPremium}</span>
                    </p>
                  </div>
                )}
                <div
                  onClick={() => handelIRightTab(2)}
                  style={{ cursor: "pointer" }}
                  className={`po_right_container_tab ${
                    rightTab == 2 ? "po_right_container_tab_active" : ""
                  }`}
                >
                  <span>Policy Holder Details</span>{" "}
                  {rightTab == 2 ? <FaMinus /> : <FaPlus />}
                </div>
                {rightTab == 2 && (
                  <div className="po_right_container_tab_active_tab">
                    <p>
                      Name{" "}
                      <span>
                        {`${motorRequest?.firstName} ${motorRequest?.middleName} ${motorRequest?.lastName}`}
                      </span>
                    </p>
                    <p>
                      Email <span>{motorRequest?.email}</span>
                    </p>
                    <p>
                      Mobile{" "}
                      <span className="number">
                        {motorRequest?.mobileNumber ||
                          apiRequestQQ?.MobileNumber}
                      </span>
                    </p>
                    <p>
                      Date of Birth{" "}
                      <span>
                        {new Date(motorRequest?.dob).toLocaleDateString(
                          "en-GB"
                        )}
                      </span>
                    </p>
                    <p>
                      City{" "}
                      <span>{motorRequest?.city || apiRequestQQ?.City}</span>
                    </p>
                    <p>
                      State{" "}
                      <span>{motorRequest?.state || apiRequestQQ?.State}</span>
                    </p>
                  </div>
                )}
                <div
                  onClick={() => handelIRightTab(3)}
                  style={{ cursor: "pointer" }}
                  className={`po_right_container_tab ${
                    rightTab == 3 ? "po_right_container_tab_active" : ""
                  }`}
                >
                  <span> Vehicle Details</span>{" "}
                  {rightTab == 3 ? <FaMinus /> : <FaPlus />}
                </div>
                {rightTab == 3 && (
                  <div className="po_right_container_tab_active_tab">
                    <p>
                      {motorRequest?.isVehicleNew
                        ? "RTO"
                        : "Registration Number"}
                      <span>
                        {motorRequest?.isVehicleNew
                          ? motorRequest?.rtoCode
                          : selectedPlan?.RegisterNumber}
                      </span>
                    </p>
                    <p>
                      Manufacturer <span>{selectedPlan?.Make}</span>
                    </p>
                    <p>
                      Model{" "}
                      <span className="number">{selectedPlan?.Model}</span>
                    </p>
                    <p>
                      RTO City{" "}
                      <span>
                        {" "}
                        {motorRequest?.rtoCode} - {motorRequest?.rtoCityName}
                      </span>
                    </p>
                    <p>
                      Engine Number <span>{motorRequest?.engineNumber}</span>
                    </p>
                    <p>
                      Chassis Number <span>{motorRequest?.chassisNumber}</span>
                    </p>
                  </div>
                )}

                {/* ----------Payment Button------------ */}
                <div className="">
                  <div className="my-2 d-flex justify-content-between w-100 gap-2">
                    <button
                      disabled={disabled}
                      type="submit"
                      onClick={(e) => createQuote(e)}
                      className="btn w-auto mx-2 payment-btn"
                      style={{
                        backgroundColor: "#56b96c",
                        color: "#ffffff",
                      }}
                    >
                      {paymentSpinner ? (
                        <span>
                          <div class="spinner-border text-light" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </span>
                      ) : (
                        <>
                          <span>Buy {selectedPlan.FinalPremium}</span>
                        </>
                      )}
                    </button>

                    <button
                      className="btn w-auto mx-2 payment-btn"
                      style={{
                        backgroundColor: "#56b96c",
                        color: "#ffffff",
                      }}
                      onClick={() => {
                        toggleSharePaymentModal();
                      }}
                    >
                      Share Payment Link
                    </button>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-center">
                  <div className="my-2">
                    {apiRequestQQ.PolicyStatus == "continue" ||
                    apiRequestQQ.NewPolicyType == "ThirdParty" ? (
                      selectedPlan.Api_name === "Kotak" ? (
                        <KotakPayment />
                      ) : (
                        <button
                          disabled={disabled}
                          type="submit"
                          onClick={(e) => createQuote(e)}
                          className="btn px-4 py-2 w-auto"
                          style={{
                            backgroundColor: "#56b96c",
                            color: "#ffffff",
                          }}
                        >
                          {apiRequestQQ.PolicyStatus != "continue" &&
                          apiRequestQQ.NewPolicyType != "ThirdParty" ? (
                            "Raise Inspection"
                          ) : paymentSpinner ? (
                            <span>
                              <div
                                class="spinner-border text-light"
                                role="status"
                              >
                                <span class="sr-only"></span>
                              </div>
                            </span>
                          ) : (
                            <>
                              <span>Buy {selectedPlan.FinalPremium}</span>
                            </>
                          )}
                        </button>
                      )
                    ) : (
                      <button
                        disabled={disabled}
                        type="submit"
                        onClick={(e) => createQuote(e)}
                        className="btn px-4 py-2 w-auto"
                        style={{ backgroundColor: "#56b96c", color: "#ffffff" }}
                      >
                        {apiRequestQQ.PolicyStatus != "continue" &&
                        apiRequestQQ.NewPolicyType != "ThirdParty" ? (
                          "Raise Inspection"
                        ) : paymentSpinner ? (
                          <span>
                            <div
                              class="spinner-border text-light"
                              role="status"
                            >
                              <span class="sr-only"></span>
                            </div>
                          </span>
                        ) : (
                          <>
                            <span>Buy {selectedPlan.FinalPremium}</span>
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
      {sharePaymentModal && (
        <SharePaymentQuotationModal
          sharePaymentModal={sharePaymentModal}
          toggleSharePaymentModal={toggleSharePaymentModal}
        />
      )}
      {/* <Modal
        isOpen={sharePaymentModal}
        toggle={toggleSharePaymentModal}
        size="md"
        centered
        backdrop="static"
      >
        <ModalBody>
          <div className="vehicle_modal">
            <div className="modal-logo">
              <a>
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-4">
              <h4 className="text-center">Share Payment Link</h4>
              <div onClick={toggleSharePaymentModal} class="v2_close false">
                <a>
                  <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className=" mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      defaultValue={shareMobileNumber}
                      name="mobileNumber"
                      onChange={(e) =>
                        handleChangeShareMobileNumber(e.target.value)
                      }
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Mobile No.<span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className=" mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <textarea
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      name="sms"
                      rows="4"
                      cols="10"
                      disabled
                      defaultValue={shareLinkText}
                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      SMS:
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </Layout>
  );
};

export default PolicyOverview;
