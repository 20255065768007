import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ProposalAccordion = () => {
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const motorRequest = useSelector((state) => state.root.motorRequest);

  const [activeAccordion, setActiveAccordion] = useState({
    vehicle: true,
    premium: false,
  });
  const [addonCoverStatus, setAddOnStatusCover] = useState(false);
  const [accidentCoverStatus, setAccidentCoverStatus] = useState(false);
  const [accessoriesCoverStatus, setAccessoriesCoverStatus] = useState(false);
  const [discountCoverStatus, setDiscountCoverStatus] = useState(false);

  const toggleAccordion = (section) => {
    setActiveAccordion((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    const addonCoversStatus = addonCovers();
    const accidentCoversStatus = accidentCovers();
    const accessoriesCoversStatus = accessoriesCover();
    const discountStatus = discountCoversStatus();
    console.log("addonCoversStatus", addonCoversStatus);
    console.log("accidentCoversStatus", accidentCoversStatus);
    console.log("accessoriesCoversStatus", accessoriesCoversStatus);
    setAddOnStatusCover(addonCoversStatus);
    setAccidentCoverStatus(accidentCoversStatus);
    setAccessoriesCoverStatus(accessoriesCoversStatus);
    setDiscountCoverStatus(discountStatus);
  }, []);

  const addonCovers = () => {
    if (
      selectedPlan?.ZeroDepriciationCover ||
      selectedPlan?.RoadSideAssistance ||
      selectedPlan?.Consumables ||
      selectedPlan?.KeyReplacement ||
      selectedPlan?.InvoiceCover ||
      selectedPlan?.NCBProtection ||
      selectedPlan?.LossOfPersonalBelongings ||
      selectedPlan?.EngineProtection ||
      selectedPlan?.TyreProtection
    ) {
      return true;
    } else {
      return false;
    }
  };

  const accidentCovers = () => {
    if (
      selectedPlan?.PassengerCover ||
      selectedPlan?.PersonalAccident ||
      selectedPlan?.liabilitiesToPaidDriver
    ) {
      return true;
    } else {
      return false;
    }
  };

  const accessoriesCover = () => {
    if (
      selectedPlan?.IsElectricalAccessories ||
      selectedPlan?.IsNonElectricalAccessories ||
      selectedPlan?.BiFuelOd ||
      selectedPlan?.BiFuelTp
    ) {
      return true;
    } else {
      return false;
    }
  };

  const discountCoversStatus = () => {
    if (
      selectedPlan?.NCBDiscount ||
      selectedPlan?.tppdDiscount ||
      selectedPlan?.discount ||
      selectedPlan?.loading
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {/* Vehicle Details Accordion */}
      <div className="accordion-section">
        <button
          className={`accordion-header ${
            activeAccordion.vehicle ? "active" : ""
          }`}
          onClick={() => toggleAccordion("vehicle")}
        >
          <span>Vehicle Details</span>
          <span className="accordion-icon">
            {activeAccordion.vehicle ? "−" : "+"}
          </span>
        </button>

        <div
          className={`accordion-content ${
            activeAccordion.vehicle ? "show" : ""
          }`}
        >
          <div className="vehicle-details">
            <div className="detail-row">
              <span className="detail-label">Registration</span>
              <span className="detail-value">
                {motorRequest?.registrationNumber} (
                {new Date(motorRequest?.registrationDate).getFullYear()})
              </span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Make & Model</span>
              <span className="detail-value">
                {`${motorRequest?.makeName} ${motorRequest?.modelName} `}
              </span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Variant</span>
              <span className="detail-value">{motorRequest?.variantName}</span>
            </div>

            <div className="detail-row">
              <span className="detail-label">IDV</span>
              <span className="detail-value">{selectedPlan?.idv}</span>
            </div>

            <div className="detail-row">
              <span className="detail-label">Registration Date</span>
              <span className="detail-value">
                {moment(motorRequest?.registrationDate).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Premium Breakup Accordion */}
      <div className="accordion-section">
        <button
          className={`accordion-header sticky-header ${
            activeAccordion.premium ? "active" : ""
          }`}
          onClick={() => toggleAccordion("premium")}
        >
          <span>Premium Breakup</span>
          <span className="accordion-icon">
            {activeAccordion.premium ? "−" : "+"}
          </span>
        </button>

        <div
          className={`accordion-content scrollable-content ${
            activeAccordion.premium ? "show" : ""
          }`}
        >
          <div className="premium-details">
            {/* Basic Premium */}
            {/* <div className="premium-subheading">Basic Covers</div> */}
            {selectedPlan?.BasicODPremium ? (
              <div className="detail-row">
                <span className="detail-label">Basic Own Damage Premium</span>
                <span className="detail-value">
                  {parseInt(selectedPlan?.BasicODPremium)}
                </span>
              </div>
            ) : null}
            {selectedPlan?.BasicTPPremium ? (
              <div className="detail-row">
                <span className="detail-label">Basic Third Party Premium</span>
                <span className="detail-value">
                  {parseInt(selectedPlan?.BasicTPPremium)}
                </span>
              </div>
            ) : null}

            {/* Add-ons Section */}
            {addonCoverStatus && (
              <div className="premium-subheading">Add-ons</div>
            )}
            {selectedPlan?.ZeroDepriciationCover && (
              <div className="detail-row nested">
                <span className="detail-label">Zero Depreciation</span>
                <span className="detail-value">
                  {selectedPlan?.ZeroDepriciationCoverAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.ZeroDepriciationCoverAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.RoadSideAssistance && (
              <div className="detail-row nested">
                <span className="detail-label">Road Side Assistance</span>
                <span className="detail-value">
                  {selectedPlan?.RoadSideAssistanceAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.RoadSideAssistanceAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.Consumables && (
              <div className="detail-row nested">
                <span className="detail-label">Consumables</span>
                <span className="detail-value">
                  {selectedPlan?.ConsumablesAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.ConsumablesAmount)}
                </span>
              </div>
            )}

            {selectedPlan?.KeyReplacement && (
              <div className="detail-row nested">
                <span className="detail-label">Key Replacement</span>
                <span className="detail-value">
                  {selectedPlan?.KeyReplacementAmount === 0
                    ? " Included"
                    : parseInt(selectedPlan?.KeyReplacementAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.InvoiceCover && (
              <div className="detail-row nested">
                <span className="detail-label">Invoice Cover</span>
                <span className="detail-value">
                  {selectedPlan?.InvoiceCoverAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.InvoiceCoverAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.NCBProtection && (
              <div className="detail-row nested">
                <span className="detail-label">NCB Protection</span>
                <span className="detail-value">
                  {selectedPlan?.NCBProtectionAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.NCBProtectionAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.LossOfPersonalBelongings && (
              <div className="detail-row nested">
                <span className="detail-label">
                  Loss Of Personal Belongings
                </span>
                <span className="detail-value">
                  {selectedPlan?.LossOfPersonalBelongingsAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.LossOfPersonalBelongingsAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.EngineProtection && (
              <div className="detail-row nested">
                <span className="detail-label">Engine Protection</span>
                <span className="detail-value">
                  {selectedPlan?.EngineProtectionAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.EngineProtectionAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.TyreProtection && (
              <div className="detail-row nested">
                <span className="detail-label">Tyre Protection</span>
                <span className="detail-value">
                  {selectedPlan?.TyreProtectionAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.TyreProtectionAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.TotalODAddons > 0 && (
              <div className="detail-row nested">
                <span className="detail-label">Total OD Addons</span>
                <span className="detail-value">
                  {parseInt(selectedPlan?.TotalODAddons)}
                </span>
              </div>
            )}

            {/* Accident Covers Section */}
            {accidentCoverStatus && (
              <div className="premium-subheading">Accident Covers</div>
            )}
            {selectedPlan?.PersonalAccident && (
              <div className="detail-row nested">
                <span className="detail-label">Personal Accident</span>
                <span className="detail-value">
                  {selectedPlan?.PaCoverAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.PaCoverAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.PassengerCover && (
              <div className="detail-row nested">
                <span className="detail-label">Passenger Cover</span>
                <span className="detail-value">
                  {selectedPlan?.PassengerCoverAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.PassengerCoverAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.LlToEmployeeCover &&
              selectedPlan?.insurerId == 28 && (
                <div className="detail-row nested">
                  <span className="detail-label">
                    Legal Liabilities To Employee Cover
                  </span>
                  <span className="detail-value">
                    {selectedPlan?.LlToEmployeeCoverAmount === 0
                      ? "Included"
                      : parseInt(selectedPlan?.LlToEmployeeCoverAmount)}
                  </span>
                </div>
              )}
            {selectedPlan?.liabilitiesToPaidDriver && (
              <div className="detail-row nested">
                <span className="detail-label">Liabilities To Paid Driver</span>
                <span className="detail-value">
                  {selectedPlan?.liabilitiesToPaidDriverAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.liabilitiesToPaidDriverAmount)}
                </span>
              </div>
            )}

            {/* Accessories Covers Section */}
            {accessoriesCoverStatus && (
              <div className="premium-subheading">Accessories Covers</div>
            )}
            {selectedPlan?.IsElectricalAccessories && (
              <div className="detail-row nested">
                <span className="detail-label">
                  Electrical Accessories Premium
                </span>
                <span className="detail-value">
                  {selectedPlan?.ElectricAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.ElectricAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.IsNonElectricalAccessories && (
              <div className="detail-row nested">
                <span className="detail-label">
                  Non Electrical Accessories Premium
                </span>
                <span className="detail-value">
                  {selectedPlan?.nonElectricalAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.nonElectricalAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.BiFuelOd && (
              <div className="detail-row nested">
                <span className="detail-label">BiFuelOd Premium</span>
                <span className="detail-value">
                  {selectedPlan?.BiFuelOdAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.BiFuelOdAmount)}
                </span>
              </div>
            )}
            {selectedPlan?.BiFuelTp && (
              <div className="detail-row nested">
                <span className="detail-label">BiFuel Tp Premium</span>
                <span className="detail-value">
                  {selectedPlan?.BiFuelTpAmount === 0
                    ? "Included"
                    : parseInt(selectedPlan?.BiFuelTpAmount)}
                </span>
              </div>
            )}

            {/* Discounts Section */}
            {discountCoverStatus && (
              <div className="premium-subheading">Discounts</div>
            )}
            {selectedPlan?.NCBDiscount && (
              <div className="detail-row nested">
                <span className="detail-label">NCB Discount</span>
                <span className="detail-value text-red-500">
                  {parseInt(selectedPlan?.NCBDiscount)}
                </span>
              </div>
            )}
            {selectedPlan?.tppdDiscount && (
              <div className="detail-row nested">
                <span className="detail-label">Tppd Discount</span>
                <span className="detail-value text-red-500">
                  {parseInt(selectedPlan?.tppdDiscount)}
                </span>
              </div>
            )}
            {selectedPlan?.discount && (
              <div className="detail-row nested">
                <span className="detail-label">OD Discount</span>
                <span className="detail-value text-red-500">
                  {parseInt(selectedPlan?.discount)}
                </span>
              </div>
            )}
            {selectedPlan?.loading && (
              <div className="detail-row nested">
                <span className="detail-label">Loading Premium</span>
                <span className="detail-value text-red-500">
                  {parseInt(selectedPlan?.loadingPremium)}
                </span>
              </div>
            )}

            {/* Total Section */}
            <div className="total-section">
              {selectedPlan?.GST && (
                <div className="detail-row">
                  <span className="detail-label">GST (18%)</span>
                  <span className="detail-value">
                    {" "}
                    {
                      selectedPlan?.GST === true ? (
                        <span>(Included)</span>
                      ) : typeof selectedPlan?.GST === "number" ? (
                        Math.round(
                          selectedPlan?.GST.toString().replace("INR", "")
                        )
                      ) : typeof selectedPlan?.GST === "string" ? (
                        Math.round(selectedPlan?.GST.replace("INR", ""))
                      ) : (
                        selectedPlan?.GST
                      )
                      // Math.round(selectedPlan?.GST)
                    }
                  </span>
                </div>
              )}
              <div className="detail-row total">
                <span className="detail-label">Total Premium</span>
                <span className="detail-value">
                  {
                    selectedPlan?.FinalPremium === true ? (
                      <span>(Included)</span>
                    ) : typeof selectedPlan?.FinalPremium === "number" ? (
                      Math.round(
                        selectedPlan?.FinalPremium.toString().replace("INR", "")
                      )
                    ) : typeof selectedPlan?.FinalPremium === "string" ? (
                      Math.round(selectedPlan?.FinalPremium.replace("INR", ""))
                    ) : (
                      selectedPlan?.FinalPremium
                    )
                    // Math.round(selectedPlan?.FinalPremium)
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalAccordion;
